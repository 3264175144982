<template>
  <div :id="anchorId">
    <table
      id="a4"
      v-if="page[idCartao - 1].check || page[idCartao - 1].check == undefined"
      :class="'a4 pg' + cartao.id"
      width="100%"
      border="0"
    >
      <tbody>
        <tr>
          <td class="td">
            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td valign="top" style="padding-top: 5px">
                    <img :src="logo" alt="logo" style="height: 50px" />
                  </td>
                </tr>
              </tbody>
            </table>
            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    align="center"
                    style="
                      font-size: 18px;
                      padding-top: 10px !important;
                      padding-bottom: 10px !important;
                      line-height: 1;
                    "
                  >
                    <strong style="font-weight: 600"
                      >AUTORIZAÇÃO DE DÉBITO {{ cartao.id }}</strong
                    >
                    - CARTÃO DE CRÉDITO
                  </td>
                </tr>
              </tbody>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    style="
                      font-size: 12px;
                      font-weight: 600;
                      border-color: #eee;
                      border-width: 1px 0px 0px 0;
                      border-style: solid;
                      padding-top: 15px;
                    "
                  >
                    Autorizo e reconheço o débito em minha conta do cartão de
                    crédito abaixo:
                  </td>

                  <td
                    style="
                      text-align: right;
                      border-color: #eee;
                      border-width: 1px 0px 0px 0;
                      border-style: solid;
                      padding-top: 15px;
                    "
                  >
                    <strong style="font-weight: 600; font-size: 11px"
                      >Produtos:</strong
                    >
                    <font
                      style="
                        background: #f1f4ff;
                        border-bottom: 2px solid #d9d9d9;
                        padding: 0px 6px;
                        font-size: 11px;
                        margin-left: 6px;
                        font-weight: 600;
                      "
                    >
                      {{ contrato.produtos }}</font
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    align="center"
                    style="font-size: 18px; height: 233px; line-height: 1"
                  >
                    <span style="position: relative; display: inline-block">
                      <font
                        style="
                          position: absolute;
                          top: 22px;
                          right: 153px;
                          font-weight: 600;
                          font-size: 14px;
                          width: 100px;
                          text-align: right;
                        "
                      >
                        {{
                          cartao.bandeira.indexOf("xxx") > -1
                            ? cartao.bandeira
                            : ""
                        }}

                        <img
                          v-if="cartao.bandeira == 'Mastercard'"
                          src="@/assets/images/mastercard.png"
                          style="width: 40px"
                        />
                        <img
                          v-if="cartao.bandeira == 'Visa'"
                          src="@/assets/images/visa.png"
                          style="width: 100%"
                        />
                        <img
                          v-if="cartao.bandeira == 'Diners'"
                          src="@/assets/images/diners.png"
                          style="width: 100%"
                        />
                        <img
                          v-if="cartao.bandeira == 'AMEX'"
                          src="@/assets/images/american.png"
                          style="width: 100%"
                        />

                        <img
                          v-if="cartao.bandeira == 'Elo'"
                          src="@/assets/images/elo.png"
                          style="width: 100%"
                        />

                        <img
                          v-if="cartao.bandeira == 'Hipercard'"
                          src="@/assets/images/hipercard.png"
                          style="width: 100%"
                        />
                      </font>

                      <font
                        style="
                          position: absolute;
                          top: 88px;
                          left: 17px;
                          font-weight: 600;
                          font-size: 12px;
                          width: 53px;
                        "
                        >{{ cartao.numeros.primeiro }}</font
                      >

                      <font
                        style="
                          position: absolute;
                          top: 88px;
                          left: 85px;
                          font-weight: 600;
                          font-size: 12px;
                          width: 53px;
                        "
                        >{{ cartao.numeros.segundo }}</font
                      >

                      <font
                        style="
                          position: absolute;
                          top: 88px;
                          left: 153px;
                          font-weight: 600;
                          font-size: 12px;
                          width: 53px;
                        "
                        >{{ cartao.numeros.terceiro }}</font
                      >

                      <font
                        style="
                          position: absolute;
                          top: 88px;
                          left: 225px;
                          font-weight: 600;
                          font-size: 12px;
                          width: 53px;
                        "
                        >{{ cartao.numeros.quarto }}</font
                      >

                      <font
                        style="
                          position: absolute;
                          top: 125px;
                          left: 23px;
                          font-weight: 600;
                          font-size: 12px;
                          width: 53px;
                        "
                        >{{ cartao.expiracao }}</font
                      >

                      <font
                        style="
                          position: absolute;
                          top: 162px;
                          left: 20px;
                          font-weight: 600;
                          font-size: 12px;
                        "
                        >{{ cartao.nome }}</font
                      >

                      <font
                        v-if="cartao.cvv != undefined"
                        style="
                          position: absolute;
                          top: 122px;
                          right: 20px;
                          font-weight: 600;
                          font-size: 13px;
                        "
                      >
                        <!-- {{ cartao.cvv }} -->
                        ***
                      </font>

                      <img
                        src="@/assets/images/credit-card-mockup.png"
                        alt="case"
                        width="420"
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              border="0"
              cellspacing="0"
              cellpadding="0"
              style="font-size: 11.8px"
              width="100%"
            >
              <tr>
                <td
                  colspan="2"
                  style="
                    border-color: #eee;
                    border-width: 1px 0px 0px 0;
                    border-style: solid;
                    padding: 10px 5px 3px 5px;
                  "
                >
                  <strong style="font-weight: 600">Valor pago: </strong>
                  <strong style="font-weight: 600"></strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ cartao.valor }}</font
                  >
                </td>
                <td
                  colspan="3"
                  style="
                    border-color: #eee;
                    border-width: 1px 0px 0px 0;
                    border-style: solid;
                    padding: 10px 5px 3px 50px;
                  "
                >
                  <strong style="font-weight: 600">Valor das taxas:</strong>
                  <strong style="font-weight: 600"> </strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                    >R$ 0,00</font
                  >
                </td>
              </tr>

              <tr>
                <td
                  colspan="2"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 10px 5px;
                  "
                >
                  <strong style="font-weight: 600">Nº de Parcelas:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                    >{{ cartao.parcelas }}</font
                  >
                </td>
                <td
                  colspan="1"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 10px 5px;
                  "
                >
                  <strong style="font-weight: 600">Valor 1ª parcela:</strong>
                  <strong style="font-weight: 600"> </strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                    >{{ cartao.primeira_parcela }}</font
                  >
                </td>

                <td
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 10px 5px;
                  "
                >
                  <strong style="font-weight: 600">Demais parcelas:</strong>
                  <strong style="font-weight: 600"> </strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ cartao.demais_parcelas }}
                  </font>
                </td>
              </tr>
            </table>

            <table
              border="0"
              cellspacing="0"
              cellpadding="0"
              style="font-size: 11.8px"
              width="100%"
              v-if="cartao.proprietario.tipo == 'client'"
            >
              <tr v-if="contrato.cliente.pessoa == 'Pessoa Física'">
                <td
                  colspan="6"
                  style="
                    border-color: #eee;
                    border-width: 0px 0 0px 0;
                    border-style: solid;
                    padding: 10px 5px 10px 5px;
                  "
                >
                  <strong style="font-weight: 600">Nome completo:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ contrato.cliente.nome }}
                  </font>
                </td>
              </tr>

              <tr>
                <td
                  colspan="6"
                  style="
                    border-color: #eee;
                    border-width: 0px 0 0px 0;
                    border-style: solid;
                    padding: 0px 5px 5px;
                  "
                >
                  <strong style="font-weight: 600">Endço. da Fatura:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 3px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ contrato.cliente.endereco.rua }},
                    {{ contrato.cliente.endereco.numero }},
                    {{ contrato.cliente.endereco.bairro }},
                    {{ contrato.cliente.endereco.cidade }},
                    {{ contrato.cliente.endereco.cep }} -
                    {{ contrato.cliente.endereco.estado }}
                  </font>
                </td>
              </tr>

              <tr v-if="contrato.cliente.pessoa == 'Pessoa Física'">
                <td
                  colspan="2"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 10px 5px;
                  "
                >
                  <strong style="font-weight: 600">CPF:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ contrato.cliente.cpf }}</font
                  >
                </td>
                <td
                  colspan="1"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 10px 5px;
                  "
                >
                  <strong style="font-weight: 600">Telefone Fixo:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ contrato.cliente.telefone }}
                  </font>
                </td>

                <td
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 10px 5px;
                  "
                >
                  <strong style="font-weight: 600">Telefone Celular:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ contrato.cliente.celular }}</font
                  >
                </td>
              </tr>

              <tr v-if="contrato.cliente.pessoa == 'Pessoa Jurídica'">
                <td
                  colspan="6"
                  style="
                    border-color: #eee;
                    border-width: 0px 0 0px 0;
                    border-style: solid;
                    padding: 15px 5px 10px 5px;
                  "
                >
                  <strong style="font-weight: 600">Nome da empresa:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ contrato.cliente.empresa }}
                  </font>
                </td>
              </tr>
              <tr v-if="contrato.cliente.pessoa == 'Pessoa Jurídica'">
                <td
                  colspan="2"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 15px 5px;
                  "
                >
                  <strong style="font-weight: 600">CNPJ:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                    >{{ contrato.cliente.cnpj }}</font
                  >
                </td>
                <td
                  colspan="1"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 15px 5px;
                  "
                >
                  <strong style="font-weight: 600">Telefone Fixo:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ contrato.cliente.telefone }}
                  </font>
                </td>

                <td
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 15px 5px;
                  "
                >
                  <strong style="font-weight: 600">Telefone Celular:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ contrato.cliente.celular }}</font
                  >
                </td>
              </tr>
            </table>

            <table
              border="0"
              cellspacing="0"
              cellpadding="0"
              style="font-size: 12px"
              width="100%"
              v-if="cartao.proprietario.tipo == 'credit-card-owner'"
            >
              <tr>
                <td
                  colspan="6"
                  style="
                    border-color: #eee;
                    border-width: 0px 0 0px 0;
                    border-style: solid;
                    padding: 15px 5px 10px 5px;
                  "
                >
                  <strong style="font-weight: 600">Nome completo:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ cartao.proprietario.nome }}
                  </font>
                </td>
              </tr>

              <tr>
                <td
                  colspan="6"
                  style="
                    border-color: #eee;
                    border-width: 0px 0 0px 0;
                    border-style: solid;
                    padding: 0px 5px 5px;
                  "
                >
                  <strong style="font-weight: 600">Endereço da Fatura:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ cartao.proprietario.endereco.rua }},
                    {{ cartao.proprietario.endereco.numero }},
                    {{ cartao.proprietario.endereco.bairro }},
                    {{ cartao.proprietario.endereco.cidade }},
                    {{ cartao.proprietario.endereco.cep }} -
                    {{ cartao.proprietario.endereco.estado }}
                  </font>
                </td>
              </tr>

              <tr>
                <td
                  colspan="2"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 15px 5px;
                  "
                >
                  <strong style="font-weight: 600">CPF:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ cartao.proprietario.cpf }}</font
                  >
                </td>
                <td
                  colspan="1"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 15px 5px;
                  "
                >
                  <strong style="font-weight: 600">Telefone Fixo:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ cartao.proprietario.telefone }}
                  </font>
                </td>

                <td
                  style="
                    border-color: #eee;
                    border-width: 0 0px 1px 0;
                    border-style: solid;
                    padding: 5px 5px 15px 5px;
                  "
                >
                  <strong style="font-weight: 600">Telefone Celular:</strong>
                  <font
                    style="
                      background: #f1f4ff;
                      border-bottom: 2px solid #d9d9d9;
                      padding: 0px 6px;
                      margin-left: 6px;
                      font-weight: 600;
                    "
                  >
                    {{ cartao.proprietario.celular }}</font
                  >
                </td>
              </tr>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    style="
                      font-size: 12px;
                      font-weight: 600;
                      border-color: #eee;
                      border-width: 0 0px 0px 0;
                      border-style: solid;
                      padding-top: 15px;
                    "
                  >
                    Passageiros favorecidos
                  </td>

                  <td
                    style="
                      text-align: right;
                      border-color: #eee;
                      border-width: 0 0px 0px 0;
                      border-style: solid;
                      padding-top: 15px;
                    "
                  ></td>
                </tr>
              </tbody>
            </table>

            <table
              width="100%"
              border="0"
              cellspacing="0"
              cellpadding="0"
              style="font-size: 12px"
            >
              <tr>
                <td
                  colspan="3"
                  style="
                    border-color: #eee;
                    border-width: 0 0px 0px 0;
                    border-style: solid;
                    padding: 10px 0 5px;
                  "
                >
                  <ul
                    style="
                      list-style: none;
                      padding: 0;
                      -webkit-column-count: 2;
                      -moz-column-count: 2;
                      column-count: 2;
                      margin-bottom: 0;
                    "
                  >
                    <li
                      style="padding-bottom: 5px; width: 100%"
                      v-for="(viajante, index) in contrato.viajantes"
                      :key="viajante"
                    >
                      <strong style="display: inline-block; width: 2%">
                        {{ index + 1 }}.</strong
                      >
                      <font
                        style="
                          background: #f1f4ff;
                          display: inline-block;
                          width: 95%;
                          border-bottom: 2px solid;
                          border-color: #d9d9d9;
                          padding: 0px 6px;
                          margin-left: 6px;
                          font-weight: 600;
                        "
                      >
                        {{ viajante.nome }}</font
                      >
                    </li>
                  </ul>
                </td>
              </tr>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    style="
                      font-size: 12px;
                      font-weight: 600;
                      border-color: #eee;
                      border-width: 0px 0px 0px 0;
                      border-style: solid;
                      padding-top: 5px;
                    "
                  >
                    Autorizo e reconheço os passageiros informados acima.
                  </td>

                  <td
                    style="
                      text-align: right;
                      border-color: #eee;
                      border-width: 0px 0px 0px 0;
                      border-style: solid;
                      padding-top: 0px;
                    "
                  ></td>
                </tr>
              </tbody>
            </table>

            <table width="100%" border="0">
              <tbody>
                <tr>
                  <td
                    valign="top"
                    width="50%"
                    style="
                      text-align: justify;
                      font-size: 10px;
                      border-color: #eee;
                      color: #666;
                      border-width: 0px 0px 0px 0;
                      border-style: solid;
                      padding: 5px 20px 5px 0;
                    "
                  >
                    <p style="margin: 0 0 5px">
                      <strong
                        style="font-size: 13px; color: #666; font-weight: 600"
                        >ATENÇÃO:
                      </strong>
                    </p>

                    <p>
                      Qualquer transação realizada fora dos padrões contratuais
                      implicará sanções legais.
                    </p>

                    <p>
                      Ao autorizar o débito no cartão de crédito, Portador e
                      Estabelecimento declaram estar cientes e concordar com as
                      seguintes condições:
                    </p>

                    <p>
                      1) Questionamentos ou cancelamentos dos serviços prestados
                      seguem diretrizes específicas do fornecedor do produto em
                      pauta.
                    </p>

                    <p>
                      2) O estabelecimento é responsável pela correta aceitação
                      do cartão, conferindo em sua apresentação a data de
                      validade, autenticidade e assinatura do titular, nos
                      termos do contrato de afiliação aos sistemas das
                      administradoras de cartão de crédito.
                    </p>
                  </td>

                  <td
                    width="50%"
                    valign="top"
                    style="
                      text-align: justify;
                      font-size: 10px;
                      border-color: #eee;
                      color: #999;
                      border-width: 0px 0px 0px 0;
                      border-style: solid;
                      ppadding: 5px 0px 5px 20px;
                    "
                  >
                    <p>
                      3) Esta autorização é válida por 10 dias e sua transmissão
                      por fax é permitida apenas para agilizar o processo de
                      venda. Em caso de contestação por parte do portador, o
                      estabelecimento é responsável pela apresentação deste
                      original devidamente preenchido e assinado, cópia frente e
                      verso do cartão, cópia de um documento ofi cial (emitido
                      por um Órgão Federal/Estadual/Municipal) que comprove a
                      identidade do portador, cópia dos bilhetes/vouchers e
                      cópia do comprovante de venda.
                    </p>

                    <p>
                      Caso os serviços sejam prestados em nome de outras
                      pessoas, além do titular do cartão, seus nomes deverão ser
                      relacionados acima, para maior segurança do portador,
                      ressaltando que a assinatura do portador do cartão neste
                      documento é obrigatória.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="html2pdf__page-break avoidThisRow"></div>
  </div>
</template>

<script>
export default {
  name: "TravelContractDebitAuthorization",
  props: {
    id: Number,
    contract: Object,
    contrato: Object,
    cartao: Object,
    anchorId: String,
    logo: String,
    page: Object,
    idCartao: Number,
  },
};
</script>

<style scoped>
#a4 .td td {
  padding: 5px 5px !important;
}
</style>